<template>
  <v-card class="mt-4">
    <v-card-title style="color: var(--v-warning-base) !important">
      {{ $t('ListItemsReadyBorrow') }}
    </v-card-title>

    <v-card-text
      v-if="!getItems.length"
    >
      {{ $t('NoData') }}
    </v-card-text>

    <div
      v-infinite-scroll="loadMore"
      infinite-scroll-disabled="isBusy"
    >
      <v-list
        three-line
      >
        <ShareBoxItem
          v-for="item in getItems"
          :key="item.id_item"
          :item="item"
          :is-staff="true"
        />
      </v-list>
    </div>
  </v-card>
</template>

<script>
import { mdiInformationOutline } from '@mdi/js'
import { mapActions, mapGetters } from 'vuex'
import { ITEM_STATUS } from '@/utils/shareBoxHelper'
import _ from 'lodash'
import ShareBoxItem from '../components/ShareBoxItem.vue'

export default {
  components: { ShareBoxItem },
  data() {
    return {
      icons: {
        mdiInformationOutline,
      },
      page: 1,
      limit: 10,
    }
  },
  computed: {
    ...mapGetters('sharebox', ['getLoading', 'getIsMore', 'getItems']),
    paramsListItem() {
      return {
        page: this.page,
        limit: this.limit,
        status: ITEM_STATUS.AVAILABLE,
      }
    },
    isBusy() {
      return this.getLoading || !this.getIsMore || !this.getItems.length
    },
  },
  created() {
    this.handleGetData()
  },
  beforeDestroy() {
    this.resetStore()
  },
  methods: {
    ...mapActions('sharebox', ['fetchListItem', 'loadMoreListItem', 'resetStore']),
    handleGetData: _.debounce(function () {
      this.fetchListItem({ ...this.paramsListItem, page: 1 })
    }, 300),
    loadMore() {
      this.loadMoreListItem({ ...this.paramsListItem, page: this.page + 1 }).then(() => {
        this.page += 1
      })
    },
  },
}
</script>
